import React from "react"

import EventTemplate from "../../components/templates/EventTemplate"
import PosterImage from "../../components/shinel-poster"
import OutlinkText from "../../components/molecules/OutlinkText"

const IndexPage = () => (
  <EventTemplate
    title={"21세기 일본 현대 문학의 행방"}
    description={`무라카미 하루키와 무라카미 류, 다와다 요코와 오가와 요코 이후 일본의
          현대문학은 어떻게 흘러가고 있는가. 21세기 일본의 젊은 작가들은 현대를
          어떻게 바라보고 소설을 어떤 방식으로 쓰고 있는가. 10편의 작품을 읽으며
          이 질문들에 대한 답을 알아가는 소설 강독 모임.`}
    ogImagePath={`/21c-japan-modern-literature-part1.png`}
  >
    <PosterImage
      filename="21c-japan-modern-literature-part1.png"
      style={{ margin: `0 auto` }}
    />
    <br />
    <p>
      무라카미 하루키와 무라카미 류, 다와다 요코와 오가와 요코 이후 일본의
      현대문학은 어떻게 흘러가고 있는가. 21세기 일본의 젊은 작가들은 현대를
      어떻게 바라보고 소설을 어떤 방식으로 쓰고 있는가. 10편의 작품을 읽으며 이
      질문들에 대한 답을 알아가는 소설 강독 모임.
    </p>
    <p>
      <strong>
        • 일시: 2025. 3.15 - 2025. 6. 7 매주 토요일 오전 10시 30분 (약 120분,
        중간 3주 휴식)
        <br />
        • 장소: 서점극장 라블레
        <br />
        • 진행: 박대겸 (소설가, 일본 근현대문학 전공)
        <br />
        • 참가비: 파트(5회)당 17만원
        <br />
        • 인원: 최대 8명
        <br />
        • 신청방법: 2.24 정오부터 PART 1 신청 가능
        <br />
        <span style={{ paddingLeft: `4.5em` }}>(PART 2는 추후 모집)</span>
        <br />• 문의:&nbsp;
        <OutlinkText href="mailto:mail@rabelais.kr">
          mail@rabelais.kr
        </OutlinkText>
      </strong>
    </p>
    <br />
    <hr />
    <br />
    <p>
      <strong>PART 1</strong>
    </p>
    <p>
      <strong>3&#x2F;15 마이조 오타로와 제로년대 『인간의 제로는 뼈』</strong>
      - 나는 성장할 수 있을까
      <br />
      <em>#문체 #성장소설 #이야기</em>
    </p>
    <p>
      <strong>3&#x2F;22 스와 데쓰시와 메타픽션 『안드로메다 남자』</strong> - 왜
      이렇게 썼을까
      <br />
      <em>#이야기 #메타픽션 #실험적</em>
    </p>
    <p>
      <strong>3&#x2F;29 다키구치 유쇼와 죽음 『산 자들의 밤』</strong> - 말하는
      자는 누구인가
      <br />
      <em>#실험적 #장례 #가족</em>
    </p>
    <p>
      <strong>4&#x2F;5 오야마다 히로코와 환상 『구멍』</strong> - 나는 왜 구멍에
      빠졌나
      <br />
      <em>#가족 #소도시 #환상</em>
    </p>
    <p>
      <strong>4&#x2F;12 사쿠라 히로와 커뮤니케이션 『탱고 인 더 다크』</strong>{" "}
      - 우리는 소통할 수 있을까
      <br />
      <em>#환상 #부부 #기억</em>
    </p>
    <br />
    <p></p>
    <p>
      <strong>PART 2 (추후 모집)</strong>
    </p>
    <p>
      <strong>오가와 사토시와 엔터테인먼트 소설 『너의 퀴즈』</strong> - 우리는
      왜 퀴즈를 푸는가
      <br />
      <em>#기억 #퀴즈 #SNS</em>
    </p>
    <p>
      <strong>우사미 린과 가족 『엄마』</strong> - 혈연이란 무엇인가
      <br />
      <em>#SNS #모녀 #출산</em>
    </p>
    <p>
      <strong>야기 에미와 픽션 『가짜 산모 수첩』</strong> – 픽션은 어떻게
      현실을 바꾸는가
      <br />
      <em>#출산 #픽션 #직장</em>
    </p>
    <p>
      <strong>다카세 준코와 먹거리 『맛있는 밥을 먹을 수 있기를』</strong> - 이
      사람은 무엇을 미워하는 걸까
      <br />
      <em>#직장 #식사 #연애</em>
    </p>
    <p>
      <strong>도노 하루카와 남성 『파국』</strong> - 그는 무엇을 잘못했을까
      <br />
      <em>#연애 #남성 #PC</em>
    </p>
    <br />
    <hr />
    <br />
    <br />
  </EventTemplate>
)

export default IndexPage
